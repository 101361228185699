#parent-circle-02 {
  position: relative;
  width: 20vw;
  height: 20vw;
  border-left: 4px solid #eb003e;
  border-radius: 50%;
  border-top: 4px solid #eb003e;
  transform: rotate(0deg);
  border-right: 2px solid #eb003e;
  border-bottom: 2px solid #eb003e;
  transition: transform 0.7s linear;
  animation: rotate 7s infinite linear;
  margin: 0 auto;

  .circle02 {
    display: block;
    position: absolute;
    width: 18%;
    height: 18%;
    margin: -8%;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform-origin: center;

    &.blue {
      background-color: #416ba9;
      transform: translate(9vw);
      top: 30%;
    }
    &.pink {
      background-color: #e6427a;
      transform: rotate(72deg) translate(10vw) rotate(-72deg);
    }
    &.lime {
      background-color: #cddb00;
      transform: rotate(144deg) translate(10vw) rotate(-144deg);
      top: 40%;
      left: 45%;
    }
    &.orange {
      background-color: #e0592a;
      transform: rotate(216deg) translate(10vw) rotate(-216deg);
      top: 61%;
      left: 45%;
    }
    &.teal {
      background-color: #57b6b2;
      transform: rotate(288deg) translate(10vw) rotate(-288deg);
    }
    &.green {
      background-color: #30b600;
      transform: rotate(360deg) translate(10vw) rotate(-360deg);
      top: 1%;
      left: -21%;
    }
    &.white {
      background-color: #4b007a;
      transform: rotate(360deg) translate(9vw) rotate(-360deg);
      top: 70%;
    }
    &.black {
      background-color: #2c2c2c;
      transform: rotate(360deg) translate(10vw) rotate(-360deg);
      top: 100%;
      left: -19%;
    }
  }
}

/* .testimonial_tabs{
    padding-top: 40px;
  } */

.userImg {
  width: 85%;
  height: 85%;
}

.circleUserImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

/* .circle {
  position: relative;
  width: 100%;
  height: 100%;
}

.box {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 50%;
  
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 4px solid #fff;
} */

/* .box {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 50%;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 4px solid #fff;
} */

.testimonial_tabs {
  transform: scale(1.3) !important;
  margin: 130px 0;
}

.test_circle {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  border: 1px solid #AF001D;
  border-radius: 50%;
}
.test_circle:before {
  content: "";
  width: 40%;
  height: 40%;
  display: block;
  position: absolute;
  border: 6px solid #AF001D;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #AF001D;
}
.test_circle:after {
  content: "";
  width: 55%;
  height: 55%;
  display: block;
  position: absolute;
  border: 1px solid #AF001D;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* background: red; */
}

.box {
  width: 15vw; /* Responsive size */
  height: 15vw; /* Responsive size */
  max-width: 80px; /* Max size */
  max-height: 80px; /* Max size */
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  border: 4px solid #fff;
  padding: 0;
}
.box img {
  width: 100%;
}

.testimonial_modal .modal-content {
  border: unset !important;
}
.testimonial_modal .modal-body {
  background: #ffebeb;
  position: relative;
  padding: 0;
}

.testimonial_modal .modalCloseBtn {
  position: absolute;
  right: -20px;
  top: -20px;
  border: unset;
  margin: 0;
  padding: 0;
  line-height: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 21px;
  font-family: "Jomolhari", serif;
  font-weight: 700;
  background: #AF001D;
  color: #fff !important;
}
.testimonial_modal .modalCloseBtn:focus {
  border: unset;
  box-shadow: unset;
}

@media (max-width: 1440px) {
  .testimonial_tabs {
    transform: scale(1.2) !important;
    margin: 80px 0;
  }
}

@media (max-width: 992px){

  .childillus img.child1 {
    bottom: -120px;
}

}
@media (max-width: 991px) {
  .testimonial_tabs {
    transform: scale(1) !important;
    margin: 0px 0;
  }

  

}


@media (max-width: 480px) {
  .testimonial_tabs {
    transform: scale(0.8) !important;
  }
}

.test_circle:after {
  background-image: url("/src/asserts/images/Group\ 1171276173.png");
  background-size: 72%;
  background-position: center;
  background-repeat: no-repeat;
}
.test_circle {
  background-image: url("/src/asserts/images/test_half_elpse.png");
  background-size: 50.7%;
  background-repeat: no-repeat;
  background-position: right;
}
.test_circle svg {
  background-image: url("/src/asserts/images/inner_test_half_circle.png");
  background-size: 40.7%;
  background-repeat: no-repeat;
  background-position: 17% 51%;
}
img.test_playIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
}

section.inner-hero.termscondition .col-lg-6 {
    display: none;
}

section.inner-hero.termscondition .inner-btn-group {
    display: none;
}

section.inner-hero.termscondition .center-content {
    padding: 3rem 0rem 14rem 0rem;
}

p,.accordion-body, a{
    font-family: poppinsRegular;
}


section.termsbg {
    margin-top: unset;
    padding-bottom: 50px;
    overflow: hidden !important;
    background-color: #feebeb;
}

.inner-hero.termscondition {
    padding: 180px 0 110px;
}
/*Porfolio Section Begin*/

.center__head__SEc h2 {letter-spacing: -3.6px; color: var(--text_color); font-size: 80px; font-weight: bold; }
.center__head__SEc h2 span {-webkit-text-fill-color: transparent; -webkit-text-stroke-width: 1.5px; -webkit-text-stroke-color: var(--text_color); }
.Portfolio__Tb__LiSt ul li {margin: 0; display: block; width: 100%; }
.Portfolio__Tb__LiSt ul li a {display: inline-block; font-size: 21px; padding: 16px 20px 12px 0; letter-spacing: -0.56px; color: #f09e2e; font-weight: 500; background: #093732; border: 2px solid transparent !important; margin-bottom: 14px !important; }
.Portfolio__Tb__LiSt ul {width: auto; border: 0; }
.Portfolio__Tb__LiSt ul li a:hover {border-bottom: 2px solid var(--primary_color) !important; }
.Portfolio__Tb__LiSt ul li a.active {font-weight: 800; color:#f7bb42 !important; }
.Portfolio__Tb__LiSt ul li {margin: 0; display: block; width: 100%; }
.Portfolio__Tb__LiSt ul li a {display: inline-block; font-size: 21px; padding: 16px 20px 12px 0; letter-spacing: -0.56px; color: var(--text_color) !important; font-weight: 500; border: 2px solid transparent !important; margin-bottom: 14px; transition: 0.5s ease-in-out; }
.Portfolio__Tb__LiSt ul {width: auto; border: 0; }
.Portfolio__Tb__LiSt ul li a:hover {border-bottom: 2px solid var(--primary_color) !important; }
.Portfolio__Tb__LiSt ul li a.active {font-weight: 800; border-bottom: 2px solid var(--primary_color) !important; }
.portfolio__img img {width: 100%; border-radius: 15px; box-shadow: 0px 0px 14px rgb(0 0 0 / 17%); height: 350px; object-fit: cover; }
.portfolio__img {margin-bottom: 30px; }
.portfolio__content span {font-size: 24px; font-weight: bold; color: var(--text_xolor); margin-bottom: 13px; display: inline-block; }
.portfolio__content p {letter-spacing: -0.4px; font-size: 20px; font-weight: 300; }
.portfolio__sec__Main {margin-bottom: 40px; height: 350px; overflow: hidden; border-radius: 10px; box-shadow: 0px 0px 17px rgb(0 0 0 / 17%); transition: 0.5s ease-in-out; }
.portfolio__sec__Main:hover {transform: scale(1.1); transition: 0.5s ease-in-out; }
.Portfolio__Tb__LiSt {position: sticky; top: 20px; }


.our__portfolio__sec {
    padding: 50px 0 0px;
}

.our__portfolio__sec .heading__center {
    text-align: center;
}

.our__portfolio__sec .heading__center h6 {
    font-family: "Teko", sans-serif;
    font-size: 38px;
    text-align: center;
}

.our__portfolio__sec .heading__center h2 {
    font-size: 80px;
    /* font-family: "Jomolhari", serif; */
    font-family: "Teko", sans-serif;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
}

.our__portfolio__sec .heading__center h2 span {
    background: -webkit-linear-gradient(#EE2849, #AF001D);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.our__portfolio__sec .tabSection .nav-tabs .nav-item.show .nav-link, .our__portfolio__sec .nav-tabs .nav-link.active {
    color: #ffffff !important;
    background: -webkit-linear-gradient(#EE2849, #AF001D);
    border-radius: 100px;
    border: 3px solid #fff;
    margin-bottom: 0;
}



.our__portfolio__sec .Portfolio__Tb__LiSt {}

.our__portfolio__sec .Portfolio__Tb__LiSt ul.nav.nav-tabs {}

.our__portfolio__sec .Portfolio__Tb__LiSt ul.nav.nav-tabs li.nav-item {
    margin-bottom: 15px;
}

.our__portfolio__sec .Portfolio__Tb__LiSt ul.nav.nav-tabs li.nav-item button.nav-link {
    width: 100%;
    padding: 12px 15px;
    font-size: 14px;
    border: 1px solid #ee2849;
    border-radius: 100px;
    font-size: 15px;
    font-weight: 500;
}







@media (max-width: 768px){

.our__portfolio__sec .col-sm-3 {max-width: 100%; flex: 100%; }
.our__portfolio__sec .col-sm-9 {max-width: 100%; flex: 100%; }
.Portfolio__Tb__LiSt ul li {display: inline-block; width: auto; padding: 10px 10px; }
.Portfolio__Tb__LiSt ul li a {font-size: 15px; padding: 10px 14px; background: #3f3e3e !important; color: #f7bb42 !important; border-radius: 10px !important; border: 0 !important; margin-bottom: 0; }
.Portfolio__Tb__LiSt {position: unset; top: 0; text-align: center; }
.Portfolio__Tb__LiSt ul {width: auto; border: 0; justify-content: center; margin-bottom: 20px; }
.Portfolio__Tb__LiSt ul li {display: inline-block; width: auto; padding: 7px 9px; margin-bottom: 0 !important; }
.Portfolio__Tb__LiSt ul li a.active {background: #f7bb42 !important; border: 0 !important; font-weight: 500; color: #000 !important; }
.our__portfolio__sec .pt-5 {padding: 15px 0 !important;} }
/*Porfolio Section End*/